import React from "react";
import styled from "styled-components";
import { queries } from "../shared/layout";

const Title = ({ title, subtitle }) => {
  return (
    <Wrapper>
      <h3>{title}</h3>
      <p>{subtitle}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: max-content;
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;

  h3 {
    font-size: 3.5rem;

    ${queries.tablet} {
      font-size: 5rem;
    }
  }

  p {
    font-family: "Signika";
    font-size: 1.8rem;

    ${queries.tablet} {
      font-size: 2.2rem;
    }
  }
`;

export default Title;
