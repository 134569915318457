import { graphql, useStaticQuery } from "gatsby";
import React from "react";

const query = graphql`
  {
    site {
      siteMetadata {
        description
        image
        siteUrl
        title
      }
    }
  }
`;

const SEO = ({ title = "", description }) => {
  const { site } = useStaticQuery(query);

  const SeoTags = [
    { name: "title", content: title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title },
    {
      name: "description",
      content: description ? description : site.siteMetadata.description,
    },
    {
      property: "og:title",
      content: title ? title : site.siteMetadata.title,
    },
    {
      property: "og:description",
      content: description ? description : site.siteMetadata.description,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      name: "twitter:title",
      content: title ? title : site.siteMetadata.title,
    },
    {
      name: "twitter:description",
      content: description ? description : site.siteMetadata.description,
    },
    { name: "og:image", content: site.siteMetadata.image },
    { name: "twitter:image", content: site.siteMetadata.image },
  ];

  return (
    <>
      {/* Standard metadata tags */}
      <title>{title ? title : site.siteMetadata.title}</title>
      <meta
        name="description"
        content={description ? description : site.siteMetadata.description}
      />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={title ? title : site.siteMetadata.title}
      />
      <meta property="og:image" content={"./static/favicon.png"} />
      <meta
        property="og:description"
        content={description ? description : site.siteMetadata.description}
      />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:card" content="website" />
      <meta
        name="twitter:title"
        content={title ? title : site.siteMetadata.title}
      />
      <meta
        name="twitter:description"
        content={description ? description : site.siteMetadata.description}
      />
      <meta property="twitter:image" content={"./static/favicon.png"} />
      {/* End Twitter tags */}
      <meta name="icon" href="./static/favicon.png" />
    </>
  );
};

export default SEO;
