const services = [
  {
    category: "Profilaktyka",
    servicesList: [
      "pełny zakres szczepień przeciwko chorobom zakaźnym psów, kotów, królików i fretek",
      "indywidualne programy szczepień",
      "szczepienia przeciwko wściekliźnie",
      "szczepienie przeciwko chorobom odkleszczowym",
      "profilaktyka przeciw pasożytom zewnętrznym i wewnętrznym (profilaktyczne badanie kału w kierunku obecności jaj pasożytów wewnętrznych)",
      "czipowanie zwierząt z wpisem w międzynarodową bazę danych",
    ],
  },
  {
    category: "Choroby wewnętrzne",
    servicesList: [
      "diagnozowanie i leczenie chorób wewnętrznych przewodu pokarmowego, wątroby, trzustki, chorób nerek i pęcherza moczowego, chorób układu rozrodczego oraz chorób układu krążenia i oddechowego",
      "badanie EKG",
      "diagnostyka chorób endokrynologicznych",
    ],
  },
  {
    category: "Diagnostyka RTG",
    servicesList: [
      "Posiadamy nowoczesną zalegalizowaną pracownię rentgenowską, której sercem jest aparat rentgenowski wysokiej częstotliwości niemieckiej firmy Gierth – lidera wśród producentów weterynaryjnych aparatów rentgenowskich współdziałający z nowoczesnym cyfrowym skanerem RTG AGFA CR10X. Dzięki temu radiodiagnostyka w naszym gabinecie jest bezpieczna, a uzyskiwane radiogramy są wysokiej jakości. Nasz aparat pozwala na wykonanie zdjęć zarówno małym zwierzętom ważącym kilkadziesiąt gramów jak i psom ras olbrzymich",
    ],
  },
  {
    category: "Diagnostyka USG",
    servicesList: [
      "Dysponujemy nowoczesnym wysokiej klasy aparatem ultrasonograficznym Samsung, pozwalającym nam na wykonywanie szczegółowych badań jamy brzusznej, płuc, serca i gałki ocznej. Obrazy zmienione chorobowo są archiwizowane i zawsze dostępne do wglądu dla właściciela lub na życzenie wydawane w formie wydruków z opisem",
    ],
  },
  {
    category: "Chirurgia",
    servicesList: [
      "sterylizacje suk i kotek",
      "kastracje psów, kocurów, królików, fretek",
      "operacyjne usuwanie guzów nowotworowych z możliwością badania histopatologicznego",
      "inne zabiegi chirurgiczne na jamie brzusznej i tkankach miękkich",
      "chirurgiczna stabilizacja złamań i inne zabiegi ortopedyczne",
    ],
  },
  {
    category: "Stomatologia",
    servicesList: [
      "ultradźwiękowe usuwanie kamienia nazębnego z polerowaniem zębów",
      "ekstrakcje zębów mlecznych i stałych",
      "korekta zgryzu gryzoni i królików",
      "leczenie chorób przyzębia",
    ],
  },
  {
    category: "Ginekologia i położnictwo",
    servicesList: [
      "badanie ginekologiczne psów i kotów",
      "określanie fazy cyklu płciowego u suk metodą cytologiczną (wymaz z dróg rodnych)",
      "wyznaczanie terminu krycia przy pomocy określenia poziomu progesteronu w surowicy krwi",
      "diagnostyka ultrasonograficzna i prowadzenie ciąży",
      "opieka okołoporodowa",
      "wykonywanie zabiegów cesarskiego cięcia, sterylizacji, kastracji oraz usuwania zmian nowotworowych listwy mlecznej",
      "diagnostyka i terapia schorzeń dróg rodnych",
      "diagnostyka i terapia schorzeń układu moczowo - płciowego samców",
    ],
  },
  {
    category: "Dermatologia",
    servicesList: [
      "diagnostyka i leczenie chorób skóry: bakteryjne stany zapalne skóry, nużyca, świerzb, APZS, dermatozy autoagresywne oraz psychogenne",
      "wykonywanie zeskrobin oraz wymazów",
      "badanie mikroskopowe włosa",
      "badanie otoskopowe zewnętrznego przewodu słuchowego",
    ],
  },
  {
    category: "Okulistyka",
    servicesList: [
      "badanie narządu wzroku - wykonywanie testów diagnostycznych",
      "diagnostyka chorób narządu wzroku",
      "leczenie infekcyjnych i nieinfekcyjnych stanów zapalnych narządu wzroku",
      "udrażnianie kanalików łzowych",
    ],
  },
  {
    category: "Dietetyka",
    servicesList: [
      "porady żywieniowe",
      "terapie odchudzające dla psów i kotów",
      "ustalenie diet leczniczych i bytowych",
    ],
  },
  {
    category: "Badania laboratoryjne",
    subcategories: [
      {
        name: "Krew",
        servicesList: [
          "badania biochemiczne: próby nerkowe i wątrobowe, enzymy trzustkowe, określanie poziomów mikro i makroelementów",
          "morfologia krwi",
          "określanie poziomu hormonów (tarczyca, nadnercza, hormony płciowe)",
          "określanie poziomu przeciwciał przeciwko chorobom zakaźnym",
        ],
      },
      {
        name: "Inne",
        servicesList: [
          "badania mikrobiologiczne (wymazy i posiewy) oraz mykologiczne",
          "badanie zeskrobin",
          "badania cytologiczne - w tym określanie fazy cyklu płciowego u suk",
          "badanie koproskopowe (badanie kału)",
          "badania genetyczne w kierunku nosicielstwa chorób dziedzicznych",
        ],
      },
      {
        name: "Badania moczu",
        servicesList: [
          "badanie ogólne, badanie osadu oraz analiza kamieni moczowych",
        ],
      },
      {
        name: "Szybkie testy diagnostyczne",
        servicesList: [
          "dla kotów FIV i FeLV oraz chorób zakaźnych u psów (nosówka, parwowiroza)",
        ],
      },
    ],
  },
  {
    category:
      "Wystawianie paszportów dla zwierząt: badania umożliwiające wyjazd do krajów UE oraz innych części świata",
    servicesList: [],
  },
  {
    category: "Zabiegi pielęgnacyjne np. czyszczenie uszu, korekta pazurków",
    servicesList: [],
  },
];

export default services;