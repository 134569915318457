import React from "react";
import styled from "styled-components";

import Layout from "../components/Layout";
import Seo from "../components/SEO";
import Title from "../components/Title";
import services from "../constants/services";

const Services = () => {
  return (
    <Layout>
      <Wrapper className="section">
        <Title
          title="Usługi"
          subtitle="Nasz gabinet świadczy kompleksowe usługi dla Państwa pupili"
        />
        <div className="services-box">
          {services.map(({ category, subcategories, servicesList }, index) => {
            return (
              <div className="service" key={index}>
                <h4>{category}</h4>
                {!subcategories && (
                  <ul>
                    {servicesList?.map((service, index) => {
                      return (
                        <li key={index}>
                          <span>→ </span>
                          {service}
                        </li>
                      );
                    })}
                  </ul>
                )}
                {subcategories && (
                  <ul>
                    {subcategories.map((subcategory, index) => {
                      return (
                        <div key={index}>
                          <h5>{subcategory.name}</h5>
                          {subcategory.servicesList.map((service, index) => {
                            return (
                              <li key={index}>
                                <span>→ </span>
                                {service}
                              </li>
                            );
                          })}
                        </div>
                      );
                    })}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      </Wrapper>
    </Layout>
  );
};

export const Head = () => {
  return <Seo title="Usługi - Animal" />;
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 120rem;
  position: relative;

  .service {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.cremeDark};

    h5 {
      margin-top: 1rem;
    }

    li {
      span {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  .img {
    position: absolute;
    bottom: 0rem;
    right: -20rem;
    height: 22rem;
    width: auto;
    z-index: 100;
  }
`;

export default Services;
