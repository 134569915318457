import React from "react";
import { FaLaptopCode } from "react-icons/fa";
import { MdFacebook } from "react-icons/md";
import styled from "styled-components";
import { queries } from "../shared/layout";

const Footer = () => {
  const fanpageUrl = "https://www.facebook.com/GabinetWeterynaryjnyAnimal";
  const pwksnUrl = "https://blinq.me/cltg7a8zi0tlzi7nbxfuzz5vt?bs=db";

  return (
    <Wrapper>
      <div>
        <p>Gabinet weterynaryjny Animal</p>
        <p>&#169; {new Date().getFullYear()} All rights reserved.</p>
      </div>
      <a href={fanpageUrl} target="_blank" rel="noopener noreferrer">
        <MdFacebook />
      </a>
      <div className="made-by">
        <div>
          <FaLaptopCode />
          <p>
            Projekt i realizacja strony: {" "}
              <a href={pwksnUrl} target="_blank" rel="noopener noreferrer">
                pwksn
              </a>
            .
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.creme};
  min-height: max-content;
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: space-around;
  margin-top: auto;

  ${queries.tablet} {
    flex-direction: row;
  }

  p {
    font-weight: 500;
    line-height: 100%;
  }

  button {
    background: transparent;
    border: none;
  }

  a {
    width: 4rem;
    height: 4rem;
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    height: 100%;
    width: auto;
    color: ${({ theme }) => theme.colors.primary};
  }

  img {
    height: 2rem;
  }

  .made-by {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .5rem;
    }

    svg {
      height: 2rem;
      color: ${({ theme }) => theme.colors.red};
    }

    a {
      font-size: 1.6rem;

      &:hover {
        text-decoration: ${({ theme }) => theme.colors.primary} wavy underline;
        text-underline-offset: 4px;
      }
    }
  }
`;

export default Footer;
