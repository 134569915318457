import { Link } from "gatsby";
import React from "react";
import { IoPaw } from "react-icons/io5";
import styled from "styled-components";
import { queries } from "../shared/layout";

const MobiNav = ({ isVisible, setIsVisible }) => {
  return (
    <Wrapper className={`${isVisible ? "visible" : ""}`}>
      <ul className="nav-links">
        <Link to="/lekarze" onClick={() => setIsVisible(false)}>
          <IoPaw className="icon" />
          <span>Lekarze</span>
        </Link>
        <Link to="/uslugi" onClick={() => setIsVisible(false)}>
          <IoPaw className="icon" />
          <span>Usługi</span>
        </Link>
        <Link to="/galeria" onClick={() => setIsVisible(false)}>
          <IoPaw className="icon" />
          <span>Galeria</span>
        </Link>
        <Link to="/kontakt" onClick={() => setIsVisible(false)}>
          <IoPaw className="icon" />
          <span>Kontakt</span>
        </Link>
      </ul>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: -100vh;
  left: 0;
  background-color: white;
  z-index: 1000;
  transition: top 0.3s;

  &.visible {
    top: 7.9rem;
    overflow-y: hidden;
  }

  ${queries.tablet} {
    display: none;

    &.visible {
      top: 10rem;
    }
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    padding: 1rem;
    border-radius: 5rem;

    a {
      width: 10rem;
      text-align: center;
      padding: 1rem;
      border-radius: 2px;
      font-weight: 500;
      height: max-content;
      transition: all 0.2s ease-in-out;
      color: ${({ theme }) => theme.colors.primaryDark};
      clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
      position: relative;

      .icon {
        color: ${({ theme }) => theme.colors.primaryDark};
        width: 5rem;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 4;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s, visibility 0.3s;
      }

      span {
        z-index: 5;
      }

      &:hover {
        display: flex;
        justify-content: center;
        transform: scale(1.1);
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.primary};

        .icon {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
`;

export default MobiNav;
