import React from "react";
import styled from "styled-components";

import Navbar from "./Navbar";
import Footer from "./Footer";
import MobiNav from "./MobiNav";
import { useState } from "react";

const Layout = ({ children }) => {
  const [isMobiNavOpen, setIsMobiNavOpen] = useState(false);

  return (
    <Wrapper>
      <Navbar
        isMobiNavOpen={isMobiNavOpen}
        setIsMobiNavOpen={setIsMobiNavOpen}
      />
      <MobiNav isVisible={isMobiNavOpen} setIsVisible={setIsMobiNavOpen} />
      {children}
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

export default Layout;
